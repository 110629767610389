@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Gilroy;
  src: url("/src/assets/fonts/Gilroy-ExtraBold.otf");
  font-weight: 800;
}
@font-face {
  font-family: Gilroy;
  src: url("/src/assets/fonts/Gilroy-Light.otf");
  font-weight: 400;
}

@layer utilities {
  .wrapper {
    @apply flex justify-start items-center flex-col w-full;
  }
  .contain {
    @apply flex w-full max-w-312 px-5 2xl:max-w-350;
  }
  .gr-text {
    @apply bg-blueGr bg-clip-text text-transparent;
  }
}

.gradient-btn::after {
  content: "";
  @apply absolute inset-0 bg-btnBg rounded-full transition-colors duration-700;
}

.gradient-btn:hover {
  @apply px-16;
}

.gradient-btn:hover::after {
  @apply bg-lightGreen;
}

.mint-btn {
  padding-inline: 32px;
  min-width: 150px;
  height: 55px;
}
.mint-btn:hover {
  min-width: 214px;
  padding-inline: 64px;
}
.MuiCircularProgress-colorPrimary {
  @apply text-white !important;
}

.roadmap::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.roadmap {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body {
  overflow-x: hidden;
  background-color: #05050f;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: #14133b #1dcf83;
}

/* Chrome, Edge, and Safari */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #0c0a1d;
}

body::-webkit-scrollbar-track:hover,
body::-webkit-scrollbar-thumb:hover body::-webkit-scrollbar-track {
  background: #1dcf83;
}
body::-webkit-scrollbar-thumb:hover {
  background-color: #1dcf83;
}

body::-webkit-scrollbar-thumb {
  background-color: #1dcf83;
  border-radius: 200px;
  border: 1px solid #1dcf83;
}
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-block: 60px;
}
.explore {
  animation: move 70s ease-in-out 0s infinite alternate;
}

.select-wallet {
  @apply transition-all duration-700 relative z-0 h-14 px-8 text-white cursor-pointer font-semibold uppercase !important;
}
.select-wallet:hover {
  @apply px-16 !important;
}
.select-wallet::before {
  content: "";
  z-index: -2;
  @apply absolute -inset-0.5 bg-btnGr rounded-full;
}
.select-wallet::after {
  content: "";
  z-index: -1;
  @apply transition-all duration-700 absolute inset-0 bg-btnBg rounded-full;
}
.select-wallet:hover::after {
  @apply bg-lightGreen;
}
iframe {
  @apply hidden;
}

@keyframes move {
  0% {
    top: 0;
    left: -40%;
  }
  25% {
    top: -35%;
    left: -40%;
  }
  50% {
    top: -40%;
    left: 10%;
  }
  75% {
    top: -40%;
    left: -40%;
  }
  100% {
    top: 0;
    left: -40%;
  }
}
